import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Launch from "@material-ui/icons/Launch";
import clsx from "clsx";
import React from "react";
import {useDispatch} from "react-redux";
import {Redirect} from "react-router-dom";
import {setErrorMessage} from "../../actions/errorActions";
import {get} from "../../actions/fetchActions";
import CenteredScreenContent from "../../components/CenteredScreenContent";
import {
  ConfirmationDialog,
  useConfirmDialog,
} from "../../components/ConfirmationDialog";
import LabelWithOverline from "../../components/LabelWithOverline";
import Spinner from "../../components/Spinner";
import {languageNameMap} from "../../config.js";
import {getUserIdToken} from "../../helpers/auth";
import {getLiveCodingDisplayInterviewType} from "../../helpers/liveCodingInterviews";
import {
  fetchInterviewUrl,
  liveCodingInterviewUrl,
} from "../../helpers/urls/interviewUrls";
import {liveCodingSurveyUrl, onsiteUrl} from "../../helpers/urls/routerUrls";
import {
  ExternalLiveCodingInterview,
  Interview,
  LiveCodingInterviewStatus,
} from "../../reducers/types";
import {useStyles} from "./InterviewHubScreen.styles";
import {LiveCodingInterviewHubExpectations} from "./LiveCodingInterviewHubExpectations";
import {LiveCodingInterviewCandidateInstructions} from "./components/LiveCodingInterviewInstructions";
import {Title} from "./components/Title";

interface Props {
  liveCodingInterviewId: string;
}

export const LiveCodingInterviewHubScreen: React.FC<Props> = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [liveCodingInterview, setLiveCodingInterview] =
    React.useState<ExternalLiveCodingInterview | null>(null);
  const [interview, setInterview] = React.useState<Interview | null>(null);

  React.useEffect(() => {
    async function getLiveCodingInterview() {
      const req = get(liveCodingInterviewUrl(props.liveCodingInterviewId));
      const res = (await dispatch(req)) as any;
      if (!("error" in res)) {
        setLiveCodingInterview(res.liveCodingInterview);
      } else {
        console.error("Error finding interview:", res.error);
        dispatch(
          setErrorMessage(
            "Your CodeCollab interview has failed to load. Please double check your URL or contact support-byteboard@karat.com.",
            {
              hideTips: true,
            },
          ),
        );
      }
    }
    const intervalId = setInterval(getLiveCodingInterview, 5000);

    return () => clearInterval(intervalId);
  }, []);

  React.useEffect(() => {
    async function getInterview() {
      if (liveCodingInterview) {
        const req = get(fetchInterviewUrl(liveCodingInterview.atsId), {
          token: await getUserIdToken(),
        });
        const res = (await dispatch(req)) as any;
        if (!("error" in res)) {
          setInterview(res);
        } else {
          console.error("Error finding interview:", res.error);
          dispatch(
            setErrorMessage(
              "Your interview has failed to load. Please double check your URL or contact support-byteboard@karat.com.",
              {
                hideTips: true,
              },
            ),
          );
        }
      }
    }
    getInterview();
  }, [liveCodingInterview]);

  const [confirmationDialog, confirmationDialogProps] = useConfirmDialog({
    title: "Are you sure you want to launch CodeCollab?",
    description:
      "Your interview can only be opened once. Please confirm it is not already running in another tab or window before launching.",
  });

  // TODO: Add back in logging about FE state

  if (!interview || !liveCodingInterview) {
    return <Spinner />;
  }

  async function onClickLaunchLiveCodingPlatform() {
    const confirmation = await confirmationDialog();
    if (confirmation) {
      const url = onsiteUrl(liveCodingInterview?.cloudRunServiceId ?? "");
      window.open(url, "_blank");
    }
  }

  // Props
  const name = interview.candidateName;

  const isLiveCodingInterviewActive =
    liveCodingInterview?.interviewStatus === LiveCodingInterviewStatus.active;

  if (!interview || !liveCodingInterview) {
    return <Spinner />;
  }

  const redirectUrl = checkForRedirect(liveCodingInterview);
  if (redirectUrl) {
    return <Redirect to={redirectUrl} />;
  }
  return (
    <CenteredScreenContent size="normal">
      <main className={classes.root}>
        <Title
          title={
            (name ? `Hi ${name}, welcome ` : "Welcome ") +
            "to your CodeCollab interview center!"
          }
        />
        {/* Interview Grid */}
        <div className={classes.interviewOverviewGrid}>
          <Box display="grid" gridRowGap="12px">
            <LabelWithOverline
              labelText={getLiveCodingDisplayInterviewType(
                liveCodingInterview,
                interview,
              )}
              overlineText="Interview Type"
            />
            <LabelWithOverline
              labelText={
                liveCodingInterview?.language
                  ? languageNameMap[liveCodingInterview?.language]
                  : "N/A"
              }
              overlineText="Language"
            />
          </Box>
          <div className={classes.interviewOverviewGridActions}>
            {isLiveCodingInterviewActive && (
              <div
                className={clsx(
                  classes.actionItemContainer,
                  classes.interviewStatus,
                )}
              >
                In Progress
              </div>
            )}
            <div className={classes.actionItemContainer}>
              <Button
                className={classes.buttonLink}
                fullWidth={true}
                startIcon={<Launch style={{fontSize: 16}} />}
                onClick={onClickLaunchLiveCodingPlatform}
                variant="contained"
                disabled={!isLiveCodingInterviewActive}
                size="large"
              >
                Launch CodeCollab
              </Button>
              <ConfirmationDialog {...confirmationDialogProps} />
              {isLiveCodingInterviewActive ? null : (
                <div>
                  ** The CodeCollab session has not begun. Check this page again
                  when the interviewer has granted access. **
                </div>
              )}
            </div>
          </div>
        </div>
        {/* Separator */}
        <hr className={classes.separator} />
        <LiveCodingInterviewCandidateInstructions
          liveCodingInterview={liveCodingInterview}
        />
        {/* Coding platform setup */}
        <Typography variant="h2">How to use the coding platform</Typography>
        <Typography paragraph={true}>
          You can access the coding interview platform by clicking the button
          above. The button will remain inactive until your interviewer grants
          access to the platform. You may need to reload the page to launch the
          editor.
        </Typography>
        <Typography paragraph={true}>
          Once you have joined the CodeCollab session a shared terminal should
          automatically be open and visible to all participants of the session.
          If you close the terminal and need to re-open it you can do so by
          clicking the Terminal row in the editor's righthand side bar.
        </Typography>
        {/* Technical requirements and setup */}
        <Typography variant="h2">Technical requirements and setup</Typography>
        <Typography paragraph={true}>
          Ensure you have the right hardware, network and software requirements
          to complete all parts of the interview. You will need:
        </Typography>
        <ul className={classes.list}>
          <li>
            <Typography>
              A computer with access to one of the following supported browsers:{" "}
              <strong>
                Google Chrome, Mozilla Firefox, Microsoft Edge, Safari, or
                Microsoft Internet Explorer 11+
              </strong>
            </Typography>
          </li>
          <li>
            <Typography>
              Reliable <strong>internet</strong> connection.
            </Typography>
          </li>
        </ul>
        {/* What to expect in your interview */}
        <Typography variant="h2">What to expect in your interview</Typography>
        <LiveCodingInterviewHubExpectations />
      </main>
    </CenteredScreenContent>
  );
};

function checkForRedirect(
  interview: ExternalLiveCodingInterview,
): string | undefined {
  if (interview.interviewStatus === LiveCodingInterviewStatus.performed) {
    return liveCodingSurveyUrl(interview.id);
  }
}
