import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import EmojiEventsOutlinedIcon from "@mui/icons-material/EmojiEventsOutlined";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import FormatQuoteIcon from "@mui/icons-material/FormatQuote";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import SelfImprovementIcon from "@mui/icons-material/SelfImprovement";
import TipsAndUpdatesOutlinedIcon from "@mui/icons-material/TipsAndUpdatesOutlined";
import {useMediaQuery} from "@mui/material";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import classNames from "classnames";
import React, {useEffect} from "react";
import {useDispatch} from "react-redux";
import {RouteComponentProps, withRouter} from "react-router-dom";
import {LogAction, logFEInfo} from "../../actions/serverInfoActions";
import {withInterview} from "../../components/InterviewLoader";
import MinimalHeader from "../../components/MinimalHeader";
import {
  accessibilityTab,
  breatherTab,
  calmAnxietyTab,
  codingExerciseTab,
  interviewHubUrl,
  interviewPlatformTab,
  technicalReasoningTab,
} from "../../helpers/urls/routerUrls";
import {
  isMobileInterview,
  isSecurityInterview,
  isDataAnalysisInterview,
  isStaffEngInterview,
  getModuleDurationMs,
} from "../../helpers/interview";
import {Interview} from "../../reducers/types";
import {
  getTechnicalReasoningInstructions,
  codingExerciseInstructions,
  staffCodingExerciseInstructions,
} from "./InterviewHubScreen";
import {useStyles} from "./InterviewHubScreen.styles";
import {useInterview} from "../../hooks/interview";
import {msToMin} from "../../util/time";

interface Props extends RouteComponentProps {
  atsId: string;
  interview: Interview;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const TabPanel = (tabPanelProps: TabPanelProps) => {
  const classes = useStyles();
  const {children, value, index, ...other} = tabPanelProps;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
      className={classes.tabPanel}
    >
      {value === index && children}
    </div>
  );
};

const InterviewHubGuideScreen = (props: Props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const interview = useInterview(props.atsId);

  useEffect(() => {
    dispatch(logFEInfo(LogAction.INTERVIEW_HUB_GUIDE_PAGE_VIEWED));
    setTabPanel();
  }, []);

  const [tabValue, setTabValue] = React.useState(0);
  const smallScreen = useMediaQuery("(max-width: 600px)");

  const setTabPanel = () => {
    const currentTab = window.location.hash;

    switch (currentTab) {
      case interviewPlatformTab:
        setTabValue(0);
        break;
      case technicalReasoningTab:
        setTabValue(1);
        break;
      case codingExerciseTab:
        setTabValue(2);
        break;
      case accessibilityTab:
        setTabValue(3);
        break;
      case calmAnxietyTab:
        setTabValue(4);
        break;
      case breatherTab:
        setTabValue(5);
        break;
      default:
        setTabValue(0);
        break;
    }
  };

  const onBack = () => {
    props.history.replace(interviewHubUrl(props.interview.id));
  };

  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const a11yProps = (index: number) => {
    return {
      id: `vertical-tab-${index}`,
      "aria-controls": `vertical-tabpanel-${index}`,
    };
  };

  const m1DurationMins = msToMin(getModuleDurationMs(interview, "m1"));

  return (
    <React.Fragment>
      <MinimalHeader title="Interview Guide" onBack={onBack} />
      <main
        className={classNames(
          classes.root,
          classes.content,
          classes.contentWithNavBar,
        )}
      >
        <Tabs
          orientation={smallScreen ? "horizontal" : "vertical"}
          variant="scrollable"
          value={tabValue}
          onChange={handleChange}
          aria-label="Vertical tabs example"
          className={classes.tabs}
          indicatorColor="primary"
        >
          <Tab
            href={interviewPlatformTab}
            label="Interview platform"
            {...a11yProps(0)}
          />
          <Tab
            href={technicalReasoningTab}
            label="Technical reasoning overview"
            {...a11yProps(1)}
          />
          <Tab
            href={codingExerciseTab}
            label="Coding exercise overview"
            {...a11yProps(2)}
          />
          <Tab
            href={accessibilityTab}
            label="Accessibility"
            {...a11yProps(3)}
          />
          <Tab
            href={calmAnxietyTab}
            label="Calm your interview anxiety"
            {...a11yProps(4)}
          />
          <Tab href={breatherTab} label="5 minute breather" {...a11yProps(5)} />
        </Tabs>
        <TabPanel value={tabValue} index={0}>
          <div className={classes.tabPanelContent}>
            <Typography variant="h2">Interview Platform</Typography>
            <Typography variant="h3">Overview</Typography>
            <Typography paragraph={true}>
              The interview platform consists of a document editor for Part 1
              (Technical Reasoning exercise) and a cloud code editor for Part 2
              (coding implementation exercise). You have the option of using
              your own local IDE for Part 2.
            </Typography>
            <Typography variant="h3">
              Technical Reasoning Design document
            </Typography>
            <Typography>
              For Part 1 of the interview, you will see a design document
              simulation where you will be collaborating with team members to
              complete the technical design of a system. On the right you will
              find instructions to complete this exercise. This panel will be
              available through the duration of the exercise and can be toggled
              to collapse/expand. On the left is the design doc where you will
              reply to team members' comments, leave your own comments and
              questions and complete the document by filling in sections like
              the conclusion.
            </Typography>
            <iframe
              className="slides"
              src="https://docs.google.com/presentation/d/e/2PACX-1vSeHar13Cjn7cX92MLOiysAbkHoaRZF_8B1b1Hkr0LTtLii7cy71-9Z2mIZ_qrz9gH8gr0Hpvdkw-U4/embed?start=false&loop=false&delayms=3000"
              frameBorder="0"
              allowFullScreen={true}
            ></iframe>
            {!isMobileInterview(props.interview) &&
              !isDataAnalysisInterview(props.interview) && (
                <>
                  <Typography variant="h3">Cloud Shell Code Editor</Typography>
                  <Typography paragraph={true}>
                    For Part 2 of the interview, you will navigate a small
                    existing code base to simulate a coding project your team is
                    working on. You will implement a few aspects of the project
                    that were discussed in the design document. You have the
                    option to use the default cloud shell code editor or your
                    own local IDE to navigate the code base and run the project.
                    If you choose to use the online cloud editor, you will have
                    access to a file explorer, an editor and a terminal to run
                    and submit your code. Read the{" "}
                    <Link
                      href="https://cloud.google.com/shell/docs/using-cloud-shell"
                      target="_blank"
                    >
                      Cloud Shell Documentation
                    </Link>{" "}
                    to learn how to customize the environment before starting
                    the interview.
                  </Typography>
                  <div
                    className={classNames(
                      classes.callout,
                      classes.calloutWithIcon,
                      classes.calloutWithInline,
                      classes.mb0,
                    )}
                  >
                    <TipsAndUpdatesOutlinedIcon />
                    <Typography>
                      <strong>Note: </strong>Cloud shell will open in a new tab
                      of your browser. So make sure to keep track of time
                      elapsed in your interview periodically
                    </Typography>
                  </div>
                  <iframe
                    className="slides"
                    src="https://docs.google.com/presentation/d/e/2PACX-1vT_2fedXyiY3fLCUCxiSLW-G2jse5uwI9PY4upIH_eNzvOuL-Y3VBmFnscCgDHPTfG1W9p_Bfk76WfY/embed?start=false&loop=false&delayms=3000"
                    frameBorder="0"
                    allowFullScreen={true}
                  ></iframe>
                </>
              )}
            <Typography variant="h3">Personal Local IDE</Typography>
            <Typography paragraph={true}>
              For Part 2 of the interview, you may download the starter code and
              complete the exercise using a personal IDE of your choice. This
              will require downloading and uploading the code via a zip file.
              You will have a chance to test this process before you begin the
              interview.
            </Typography>
            {!isMobileInterview(props.interview) &&
              !isDataAnalysisInterview(props.interview) && (
                <>
                  <Typography variant="h3">
                    Switching Between Code Editors
                  </Typography>
                  <Typography paragraph={true}>
                    At any point before and during the interview, you have the
                    option of switching between using the Cloud Shell editor and
                    your local IDE. Simply click the{" "}
                    <HelpOutlineOutlinedIcon
                      className={classes.miniHelpButton}
                      aria-label="help icon"
                    />
                    button in the top right corner of your screen where you will
                    find instructions on how to do so.
                  </Typography>
                </>
              )}
          </div>
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <div className={classes.tabPanelContent}>
            <Typography variant="h2">Technical Reasoning Exercise</Typography>
            <Typography variant="h3">The Setup</Typography>
            {getM1Setup(props.interview)}
            <Typography variant="h3">Time Guidance</Typography>
            <Typography component="div">
              {getTechnicalReasoningInstructions(
                props.interview,
                m1DurationMins,
              )}
            </Typography>
            <Typography variant="h3">What are we looking for</Typography>
            <Typography>
              In this exercise, we are looking for things like:
            </Typography>
            <Typography component="div">
              <ul>
                <li>
                  How you familiarize yourself with your team's technical
                  projects
                </li>
                <li>
                  How you consider business and user needs when making technical
                  decisions
                </li>
                <li>
                  How you communicate technical concepts and trade-offs with
                  your team members and stakeholders
                </li>
                <li>
                  How you predict and solve problems early on in a project's
                  lifecycle
                </li>
                <li>
                  How you understand the big picture and plan for a robust
                  implementation
                </li>
              </ul>
            </Typography>
            <Typography variant="h3">Sample Interview Content</Typography>
            <iframe
              className="figma-prototype"
              src="https://embed.figma.com/proto/73qWv9EB3TsRoxDePpMJ7e/Byteboard-Practice-Interview?node-id=191-460&p=f&scaling=min-zoom&content-scaling=fixed&page-id=191%3A8&embed-host=share"
              allowFullScreen
            ></iframe>
          </div>
        </TabPanel>
        <TabPanel value={tabValue} index={2}>
          <div className={classes.tabPanelContent}>
            <Typography variant="h2">Coding Exercise</Typography>
            <Typography variant="h3">The Setup</Typography>
            {getM2Setup(props.interview)}
            <Typography variant="h3">Time Guidance</Typography>
            <Typography component="div">
              {isStaffEngInterview(props.interview)
                ? staffCodingExerciseInstructions
                : codingExerciseInstructions}
            </Typography>
            <Typography variant="h3">What are we looking for</Typography>
            <Typography>
              In this exercise, we are trying to understand your familiarity
              with basic coding concepts like:
            </Typography>
            <Typography component="div">
              <ul>
                {isDataAnalysisInterview(props.interview) ? (
                  <li>
                    Manipulating data sets programmatically to answer ressarch
                    questions
                  </li>
                ) : (
                  <li>
                    Reading and understanding code written by other engineers
                  </li>
                )}
                {isDataAnalysisInterview(props.interview) ? (
                  <li>
                    Clearly documenting and explaining the results of analysis
                  </li>
                ) : (
                  <li>Working with basic algorithms and data structures</li>
                )}
                <li>
                  Ability to interpret and code with ambiguous or open-ended
                  requirements
                </li>
                <li>
                  Leveraging existing functionality or language constructs
                </li>
                <li>Writing clean, readable and well documented code</li>
              </ul>
            </Typography>
            <Typography variant="h3">Dos and Don'ts</Typography>
            <Typography component="div">
              <ul>
                <li>
                  Do write clean and well commented code. State your
                  assumptions, questions, or thoughts in clear comments.
                </li>
                <li>Do try to submit code that runs and works as expected.</li>
                <li>
                  Do pay attention to edge cases and consider adding test cases
                  to catch edge cases or future regressions.
                </li>
                <li>
                  Do use any resources available only (documentation, stack
                  overflow, etc) but don't copy code directly. All submissions
                  should be entirely your own.
                </li>
                <li>
                  Do cite your references if using external documentation.
                </li>
                <li>
                  Do write pseudocode for tasks or improvements you did not
                  complete.
                </li>
                {!isDataAnalysisInterview(props.interview) &&
                  !isSecurityInterview(props.interview) && (
                    <li>
                      Don't use third-party libraries, APIs, or code for this
                      exercise (language built-ins only).
                    </li>
                  )}
              </ul>
            </Typography>
            <Typography variant="h3">Sample Interview Content</Typography>
            <iframe
              className="figma-prototype"
              src="https://embed.figma.com/proto/73qWv9EB3TsRoxDePpMJ7e/Byteboard-Practice-Interview?node-id=191-822&p=f&scaling=min-zoom&content-scaling=fixed&page-id=191%3A665&embed-host=share"
              allowFullScreen
            ></iframe>
            <Typography variant="h2">After the interview</Typography>
            <Typography paragraph={true}>
              After you complete all parts of the interview, you may optionally
              provide a short write-up of what improvements and optimizations
              you would have made to your solution if you had more time. This is
              an opportunity to demonstrate how you would take your
              implementation forward or how you would do things differently if
              this project was part of your job. You will have{" "}
              <strong>5 minutes</strong> to complete this part.
            </Typography>
          </div>
        </TabPanel>
        <TabPanel value={tabValue} index={3}>
          <div className={classes.tabPanelContent}>
            <Typography variant="h2">Accessibility</Typography>
            <Typography variant="h3">Extended time</Typography>
            <Typography paragraph={true}>
              Please reach out to your recruiter as soon as possible if you
              require extended time as per the ADA guidelines for standardized
              testing. Confirm that changes to the interview duration have been
              made before starting the interview.{" "}
              <Link
                href="https://www.ada.gov/regs2014/testing_accommodations.html"
                target="_blank"
              >
                Read the ADA requirements for more information.
              </Link>
            </Typography>
            <Typography variant="h3">Assistive Technology</Typography>
            <Typography paragraph={true}>
              If you are planning to use a screen reader and/or would like to
              navigate with your keyboard in the technical design document, you
              must reach out to your recruiter prior to starting the interview.
              We recommend using Caret browsing and the keyboard shortcuts
              described in the next section.
            </Typography>
            <Typography paragraph={true}>
              To complete the coding implementation parts of your interview, we
              recommend choosing the zip file option. This will allow you to
              download a zip file and use your personal IDE.
            </Typography>
            <Typography paragraph={true}>
              If you run into any issues during this process, please reach out
              to the Byteboard team, and we will provide assistance.
            </Typography>
            <Typography variant="h3">
              Keyboard Shortcuts for the Technical Reasoning Exercise
            </Typography>
            <Typography paragraph={true}>
              Here's how you can interact with the Byteboard design document
              using only the keyboard.
            </Typography>
            <Typography paragraph={true}>
              Caret Browsing can be toggled on and off in Chrome and Firefox
              using the keyboard shortcut F7. Caret browsing will allow you to
              select text in the document using your keyboard, so you can leave
              comments.
            </Typography>
            <Typography variant="h4">Add a new comment</Typography>
            <Typography paragraph={true}>
              Select the relevant text and use “Command+Option+M” or
              “Control+Alt+M” to add a new comment. After typing your comment,
              pressing Esc transfers focus back to the highlighted text.
            </Typography>
            <Typography variant="h4">Move between comment boxes</Typography>
            <Typography paragraph={true}>
              When your focus is on a comment box, you can use the Arrow Up and
              Arrow Down arrow keys to navigate between comment boxes. When you
              are at the end of a comment text field, pressing down will take
              you to the next comment. When you are at the beginning of a
              comment text field, pressing up will take you to the previous
              comment.
            </Typography>
            <Typography variant="h4">Find the next comment</Typography>
            <Typography paragraph={true}>
              When the focus is on the main page, use the Tab key to find the
              next highlighted area. If you are using a screen reader, the
              announcements will read “Comment start” and “Comment end” to
              denote the text that is highlighted. Now the focus is on the
              highlight, and you can use the “enter” key to focus on the
              associated comment and “Esc” key to focus back to the highlight.
            </Typography>
            <Typography variant="h4">Editing and deleting comments</Typography>
            <Typography paragraph={true}>
              Changes in the comment boxes are saved by default. To edit or
              delete a comment, simply move the focus to the comment text field
              and edit or delete text.
            </Typography>
            <Typography variant="h3">Other Accommodations</Typography>
            <Typography paragraph={true}>
              Reach out to your recruiter if you have a request that is not
              covered above. We will try our best to accommodate additional
              requests sent to us by your recruiter.
            </Typography>
          </div>
        </TabPanel>
        <TabPanel value={tabValue} index={4}>
          <div className={classes.tabPanelContent}>
            <Typography variant="h2">Calm your interview anxiety</Typography>
            <Typography paragraph={true}>
              Anxiety before an interview is completely normal and expected. It
              is just a sign that you want to do well especially if you have
              been spending time and effort preparing for it. Many times the
              interview anxiety comes from not knowing what to expect during the
              interview. By reading and understanding the resources in this hub
              you're already several steps towards easing that anxiety. Here are
              a few tips on further calming your interview anxiety.
            </Typography>
            <div className={classes.headerWithIcon}>
              <EmojiEventsOutlinedIcon />
              <Typography variant="h3">Visualize success</Typography>
            </div>
            <Typography paragraph={true}>
              A tactic that is often practised by athletes is to visualize
              themselves perform successfully in high pressure situations. Now
              that you're familiar with the interview format and expectations,
              mentally rehearse the different steps you would take as the
              interview unfolds. Get into a comfortable position, take a deep
              breath and focus on the mindset you want to be in during the
              interview. Imagine yourself sitting at your desk starting the
              interview. Walk through each screen and narrate what steps you
              would take to successfully complete your interview.
            </Typography>
            <div className={classes.headerWithIcon}>
              <SelfImprovementIcon />
              <Typography variant="h3">Relax the day before</Typography>
            </div>
            <Typography paragraph={true}>
              Try not to do any last-minute studying and instead use your time
              to do something fun and relaxing that will take your mind off the
              next day. Find an activity that is engaging enough that helps you
              focus on something outside of yourself. Activities such as
              watching a film, reading an engaging book or socializing with
              other people will force your mind to be distracted.
            </Typography>
            <div className={classes.headerWithIcon}>
              <FavoriteBorderOutlinedIcon />
              <Typography variant="h3">
                Embrace positive affirmations
              </Typography>
            </div>
            <Typography paragraph={true}>
              Verbalizing your thoughts make them feel more real and often the
              language we use to talk to ourselves can have a powerful effect on
              our beliefs. Take a minute to say out loud or write down a
              positive affirmation that will help brighten your mood and help
              you feel confident before your interview. Try these out:
            </Typography>
            <div className={classes.quote}>
              <FormatQuoteIcon className={classes.quoteIcon} />
              <Typography variant="body2">
                I'm great at ______ and this will help me succeed at my
                interview.
              </Typography>
            </div>
            <div className={classes.quote}>
              <FormatQuoteIcon className={classes.quoteIcon} />
              <Typography variant="body2">
                The result of an interview isn't a judgement of who I am as a
                person.
              </Typography>
            </div>
          </div>
        </TabPanel>
        <TabPanel value={tabValue} index={5}>
          <div className={classes.tabPanelContent}>
            <Typography variant="h2">5 minute breather</Typography>
            <Typography paragraph={true}>
              Got 5 minutes? We encourage you to follow along with these guided
              breathing and stretching videos before your interview or during
              the break. They are designed to help you feel centered, relaxed,
              and ready to show off your strengths during the interview!
            </Typography>
            <iframe
              className="video"
              src="https://www.youtube.com/embed/aNXKjGFUlMs"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              frameBorder="0"
              allowFullScreen
            ></iframe>
            <iframe
              className="video"
              src="https://www.youtube.com/embed/3UETApM_L-I"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              frameBorder="0"
              allowFullScreen
            ></iframe>
          </div>
        </TabPanel>
      </main>
    </React.Fragment>
  );
};

function getM1Setup(interview: Interview): JSX.Element {
  const m1DurationMins = msToMin(getModuleDurationMs(interview, "m1"));
  if (isDataAnalysisInterview(interview) || isSecurityInterview(interview)) {
    return (
      <Typography paragraph={true}>
        For this exercise you will have {m1DurationMins} minutes to collaborate
        on and complete a technical design document. One of your hypothetical
        teammates has started to outline the technical design for a new project
        that y'all are going to be working on together. They have gathered the
        requirements and have worked with the team to develop an unfinished
        draft of a design document. Your teammate is now looking to you to use
        your role-related knowlege and critical thinking skills to help fill in
        the rest.
      </Typography>
    );
  }

  if (isStaffEngInterview(interview)) {
    return (
      <Typography paragraph={true}>
        For this exercise you will have {m1DurationMins} minutes to collaborate
        on and complete a technical design document. Your hypothetical team has
        started to outline the technical design for a systems redesign that
        y'all are going to be working on together. They have gathered the
        requirements and have worked with the team to develop an unfinished
        draft of a design document. Your team is now looking to you to use your
        role-related knowlege and critical thinking skills to help fill in the
        rest.
      </Typography>
    );
  }

  return (
    <Typography paragraph={true}>
      For this exercise you will have {m1DurationMins} minutes to collaborate on
      and complete a technical design document. One of your hypothetical
      teammates has started to outline the technical design for a new project
      that y'all are going to be working on together. They have gathered the
      requirements and have worked with the team to brainstorm implementation
      options. Your teammate then outlined pros and cons of each option to the
      best of their knowledge and would like you to help complete the technical
      design by choosing an option.
    </Typography>
  );
}

function getM2Setup(interview: Interview): JSX.Element {
  const m2DurationMins = msToMin(getModuleDurationMs(interview, "m2"));
  if (isDataAnalysisInterview(interview) || isSecurityInterview(interview)) {
    return (
      <Typography paragraph={true}>
        For this exercise you will have {m2DurationMins} minutes to contribute
        to the coding implementation of the project discussed in Part 1. While
        the context carries over, your responses in Part 1 will have no impact
        on Part 2 and can be treated as independent. As part of your
        hypothetical team, you have been assigned a few tasks to complete in a
        small shared multi-file codebase. Inside the codebase is a README file,
        written by your teammates, which describes the tasks that are left for
        you to complete.
      </Typography>
    );
  }
  if (isStaffEngInterview(interview)) {
    return (
      <Typography paragraph={true}>
        For this exercise you will have {m2DurationMins} minutes to contribute
        to the coding implementation of features within the system discussed in
        Part 1. While the context carries over, your responses in Part 1 will
        have no impact on Part 2 and can be treated as independent. As part of
        your hypothetical team, you have been assigned a few tasks to complete
        in a small shared multi-file codebase. Inside the codebase is a README
        file, written by your teammates, which describes the tasks that are left
        for you to complete.
      </Typography>
    );
  }
  return (
    <Typography paragraph={true}>
      For this exercise you will have {m2DurationMins} minutes to contribute to
      the coding implementation of the project discussed in Part 1. While the
      context carries over, your responses in Part 1 will have no impact on Part
      2 and can be treated as independent. As part of your hypothetical team,
      you have been assigned to complete a few methods in a small shared
      multi-file code base to create a working prototype of the project. Your
      teammates have bootstrapped and completed a majority of the scaffolding.
      Use the README file as guidance to navigate to the right files and begin
      your tasks.
    </Typography>
  );
}

export default withInterview([], {ignoreGlobalRules: ["terms", "getStarted"]})(
  withRouter(InterviewHubGuideScreen),
);
