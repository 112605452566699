import Typography from "@material-ui/core/Typography";
import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import remarkGfm from "remark-gfm";
import {setErrorMessage} from "../../../actions/errorActions";
import {get} from "../../../actions/fetchActions";
import {Markdown} from "../../../components/Markdown";
import {liveCodingConfigurationUrl} from "../../../helpers/urls/interviewUrls";
import {
  ExternalLiveCodingConfiguration,
  ExternalLiveCodingInterview,
  LiveCodingInterviewStatus,
} from "../../../reducers/types";
import {useStyles} from "../InterviewHubScreen.styles";

interface Props {
  liveCodingInterview: ExternalLiveCodingInterview;
}
export const LiveCodingInterviewCandidateInstructions = ({
  liveCodingInterview,
}: Props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [liveCodingConfiguration, setLiveCodingConfiguration] =
    useState<ExternalLiveCodingConfiguration | null>(null);

  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout>;

    async function pollLiveCodingConfiguration() {
      const req = get(liveCodingConfigurationUrl(liveCodingInterview.id));
      const res = (await dispatch(req)) as unknown as {
        liveCodingConfiguration: ExternalLiveCodingConfiguration;
      };

      if (!("error" in res)) {
        setLiveCodingConfiguration(res.liveCodingConfiguration);
      } else {
        console.error("Error finding interview:", res.error);
        dispatch(
          setErrorMessage(
            "Your CodeCollab interview instructions have failed to load. Please contact support-byteboard@karat.com.",
            {
              hideTips: true,
            },
          ),
        );
      }

      timeout = setTimeout(pollLiveCodingConfiguration, 5000);
    }

    if (
      liveCodingInterview.interviewStatus === LiveCodingInterviewStatus.active
    ) {
      pollLiveCodingConfiguration();
    }

    return () => clearTimeout(timeout);
  }, [
    dispatch,
    liveCodingInterview.id,
    liveCodingInterview.interviewStatus,
    setLiveCodingConfiguration,
  ]);

  if (
    !liveCodingConfiguration ||
    !liveCodingConfiguration.candidateInstructions
  ) {
    return null;
  }
  return (
    <>
      <Typography variant="h2">Interview Instructions</Typography>
      <Markdown remarkPlugins={[remarkGfm]}>
        {liveCodingConfiguration.candidateInstructions}
      </Markdown>
    </>
  );
};
