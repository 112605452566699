import Link from "@material-ui/core/Link";
import * as React from "react";

const SupportEmail = () => {
  return (
    <Link
      underline="always"
      href="mailto:support-byteboard@karat.com?subject=Support Request"
      target="_blank"
    >
      support-byteboard@karat.com
    </Link>
  );
};

export default SupportEmail;
